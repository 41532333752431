import React, { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@btc/shared";

import { Meta } from "./common/meta";
import { Calendar } from "./screen/calendar";

const Template = ({ data }) => {
  const meta = getProperty(data, "page.frontmatter.meta");
  const calendar = {
    ...getProperty(data,"page.frontmatter.screen.calendar"),
    schedules: getProperty(data, "schedules.frontmatter.event.schedules")
  };

  return (
    <Fragment>
      <Meta data={meta} />
      <Calendar data={calendar} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
        screen {
          calendar {
            title
            labels {
              current {
                title
                empty
              }
              next {
                title
                empty
              }
            }
            delay_in_minutes
          }
        }
      }
    }

    schedules: markdownRemark(frontmatter: { locale: { eq: $locale }, type: { eq: "event" } }) {
      ...ScreenSchedulesFragment
    }
  }
`;
