import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import { Text, Title, getProperty } from "@btc/shared";

import { LogoLarge } from "theme";

import * as styles from "./calendar.module.scss";

const LOCALES = ["de-DE"];
const TIMEZONE = "Europe/Vienna";
const CATEGORY_BLACKLIST = ["workshop"];

const isSameDate = (dateA, dateB) => (
  dateA.getFullYear() === dateB.getFullYear() &&
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getDate() === dateB.getDate()
);

const makeHarmonizedDate = (date = new Date(), offsetInMinutes = 0) => {
  const dateString = new Date(date).toLocaleString("en-US", {
    timeZone: TIMEZONE,
  });

  const offset = offsetInMinutes * 60000;
  const harmonized = +new Date(dateString);

  return new Date(harmonized + offset);
};

const sortTalks = (talks) =>
  talks.sort((a, b) => {
    const dateA = new Date(getProperty(a, "from"));
    const dateB = new Date(getProperty(b, "from"));
    return dateA - dateB;
  });

const findTalksForDate = (schedules, now) => {
  const talks = schedules.flatMap(({ talks }) =>
    talks.filter(({ from , to }) =>
      isSameDate(makeHarmonizedDate(from), now) ||
      isSameDate(makeHarmonizedDate(to), now)
    ).filter(({ category }) => {
      const alias = getProperty(category,"alias");
      return !CATEGORY_BLACKLIST.includes(alias);
    })
  );

  return sortTalks(talks);
}


const findCurrentTalks = (talks, delay, now) => talks
  .filter(({ title, from, to }) => {
    const dateFrom = makeHarmonizedDate(from, delay);
    const dateTo = makeHarmonizedDate(to, delay);

    return now.getTime() >= dateFrom.getTime() && now.getTime() < dateTo.getTime();
  })
  .map((item) => ({ ...item, delay }));

const findNextTalks = (talks, delay, now) =>  talks
  .filter(({ from }) => {
    const dateFrom = makeHarmonizedDate(from, delay);
    return isSameDate(dateFrom, now) && dateFrom.getTime() > now.getTime();
  })
  .map((item) => ({ ...item, delay }));


const reduceState = (schedules, delay) => {
  const now = makeHarmonizedDate();
  const talks = findTalksForDate(schedules, now);

  return {
    current: findCurrentTalks(talks, delay, now),
    next: findNextTalks(talks, delay, now),
    talks,
  };
};

const renderItem = ({ title, subtitle, from, to, delay }, index) => {
  const timeFrom = makeHarmonizedDate(from, delay).toLocaleTimeString(LOCALES, {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: TIMEZONE,
  });
  const timeTo = makeHarmonizedDate(to, delay).toLocaleTimeString(LOCALES, {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: TIMEZONE,
  });

  return (
    <div className={styles.item} key={index}>
      <Text size="s4" weight="bold">{`${timeFrom} - ${timeTo}`}</Text>
      <Text size="s4" weight="bold">
        {title}
      </Text>
      <Text size="s3" transform="uppercase" className={styles.subtitle}>
        {subtitle}
      </Text>
    </div>
  );
};

export const Calendar = ({ data }) => {
  const title = getProperty(data, "title");
  const schedules = getProperty(data, "schedules") || [];
  const delay = getProperty(data, "delay_in_minutes") || 0;
  const labelNextTitle = getProperty(data, "labels.next.title");
  const labelNextEmpty = getProperty(data, "labels.next.empty");
  const labelCurrentTitle = getProperty(data, "labels.current.title");
  const labelCurrentEmpty = getProperty(data, "labels.current.empty");

  const [state, setState] = useState(() => reduceState(schedules, delay));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setState(reduceState(schedules, delay));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [schedules, delay]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <LogoLarge color="mixed" className={styles.icon} />
      </div>

      <Title size="s6" wrapper="h1" className={styles.title}>
        {title}
      </Title>
      {state.schedule && <Title size="s4">{state.schedule.title}</Title>}

      <div className={styles.items}>
        <Title size="s5" color="black" transform="uppercase">
          {labelCurrentTitle}
        </Title>
        {state.current.length > 0 ? (
          state.current.map(renderItem)
        ) : (
          <Text size="s3" type="special">
            {labelCurrentEmpty}
          </Text>
        )}
      </div>
      <div className={styles.items}>
        <Title size="s5" color="black" transform="uppercase">
          {labelNextTitle}
        </Title>
        {state.next.length > 0 ? (
          state.next.map(renderItem)
        ) : (
          <Text size="s3" type="special">
            {labelNextEmpty}
          </Text>
        )}
      </div>
    </div>
  );
};

Calendar.defaultProps = {
  data: {},
};

Calendar.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    labels: PropTypes.object.isRequired,
    delay_in_minutes: PropTypes.number,
    schedules: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }).isRequired,
};

export const query = graphql`
  fragment ScreenSchedulesFragment on MarkdownRemark {
    frontmatter {
      event {
        schedules {
          name
          from
          to
          talks {
            title
            subtitle
            from
            to
            category {
              alias
            }
          }
        }
      }
    }
  }
`;
